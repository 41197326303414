<template>
  <div class="setting">
    <h2 class="mt-10 mb-0 text-center">
      <span class="half_line">{{$t('view_settings_title')}}</span>
    </h2>



    
    <div
      class="mb-5 mt-5 text--disabled text-center"
      style="font-size: 14px; font-weight: normal"
    >
      {{$t('view_settings_title_text_backtest')}}



    </div>      

    



    <v-form
      ref="settingForm"
      @submit.prevent="saveSettings"
      lazy-validation
      class="mt-0"
    >
      <div style="max-width: 320px" class="mx-auto">
        <div>

<h5 class="my-5 text-center" style="color:#888">{{$t('view_settings_stitle_system_option')}}</h5>

          <v-select class="mb-5"
            :label="$t('view_settings_select_exchange')"
            :items="server_type"
            
            v-model="systemOption.exchange"
            required
            outlined
            dense
            @change="isChanged"
            hide-details=""
            hint=""
          ></v-select>



          <v-select class="mb-5"
            v-if="!$store.state.disable_multilang"
            :label="$t('view_settings_select_lang')"
            :items="lang_option"
            v-model="systemOption.lang"
            required
            outlined
            dense
            hide-details=""
            hint=""
          ></v-select>

          <v-autocomplete  
            :label="$t('view_settings_select_symbols')+'('+market_type.length+')'"
            outlined
            dense
            :items="market_type"
            item-value="symbol"
            item-text="symbol"
            v-model="target_symbols"
            multiple
            placeholder="Select..."
            required
            class="mb-2"
            hide-details=""
            @change="isChanged"
            
          :counter="maxNumberOfChoices"
          :rules="validationRules"

           chips
           small-chips
          >
        
          <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeSymbol(data.item)"
                  small 
                  style="top:3px;;margin-top:5px;margin-bottom:5px;"
                >
                  {{ data.item.symbol }}
                </v-chip>
          </template>
        </v-autocomplete>


<v-divider class="mt-5"></v-divider>



















          <v-btn type="submit"
          class="mb-3"
          :disabled="$store.state.serverinfo.server_full"
          @click.prevent="saveSettings()" block color="primary"> {{$t('view_settings_bt_save_settings')}} </v-btn>

<p style="text-align:center;color:red;" v-if="$store.state.serverinfo.server_full"><small>{{$t('view_settings_txt_server_full')}}</small></p>

<!--
            <v-btn
          :disabled="!is_changed"
          v-if="is_changed"
              type="button"
              plain
              block
              small
              @click.prevent="restoreSettings()"
              class="mt-2"
            >
              <v-icon v-if="false" style="font-size:120%">mdi-arrow-u-right-bottom</v-icon>
              {{$t('view_settings_bt_restore_settings')}}
            </v-btn>

            <v-btn
              type="button"
              plain
              block
              small
              @click.prevent="exportSettings()"
              class="mt"
            >
              {{$t('view_settings_bt_export_settings')}}
            </v-btn>
-->
            <!--
            <v-btn
          v-if="!$store.state.serverinfo.server_full"
              type="button"
              plain
              block
              small
              @click.prevent="importSettings()"
              class="mt"
            >
              {{$t('view_settings_bt_import_settings')}}
            </v-btn>
            -->
            
            <v-btn
              type="button"
              plain
              block
              small
              @click.prevent="resetSettings()"
              class="mt"
            >
              {{$t('view_settings_bt_reset_settings')}}
            </v-btn>



        </div>
      </div>
    </v-form>

    <!--


    <h1>This is an setting page</h1>

    <pre>
- 텔레그램 key
- 분봉정보
- 기타정보
- 매매정보
- 큰 볼륨 진입제한
- 분봉 변경 1,3,5분
- 진입금지 시간 설정
    </pre>
    -->
  </div>
</template>

<script>
//import {  shell } from "electron";


/*
let callbackFunction = (status) => {
  console.log(status);

    if (status) {
    // (5) 필수적으로 받아야하는 프로필 정보가 있다면 callback처리 시점에 체크

    var email = naverLogin.user.getEmail();
    if( email == undefined || email == null) {
      alert("이메일은 필수정보입니다. 정보제공을 동의해주세요.");
      // (5-1) 사용자 정보 재동의를 위하여 다시 네아로 동의페이지로 이동함
      naverLogin.reprompt();
      return;
    }

    window.location.replace("http://" + window.location.hostname + ( (location.port==""||location.port==undefined)?"":":" + location.port) + "/sample/main.html");
  } else {
    console.log("callback 처리에 실패하였습니다.");
  }
}*/

export default {
  name: "Setting",
  title: "Login",
  components: {},
  data() {
    const self = this;
    const starting_option = [
        { text: self.$t('view_settings_starting_option_auto'), value: "auto" },//{ text: "최근 상태 보존", value: "auto" },
        { text: self.$t('view_settings_starting_option_off'), value: "off" },//{ text: "모든 봇 끄기", value: "off" },
        { text: self.$t('view_settings_starting_option_on'), value: "on" },//{ text: "모든 봇 켜기", value: "on" },
      ];

    const lang_option = [
        { text: "English", value: 'en' },
        { text: "한국어", value: 'ko' },
      ];

      
    const candle_type = [
        { text: self.$t('view_settings_starting_option_on'), value: 1 },//{ text: "1분", value: 1 },
        { text: self.$t('view_settings_starting_option_on'), value: 3 },//{ text: "3분", value: 3 },
        { text: self.$t('view_settings_starting_option_on'), value: 5 },//{ text: "5분", value: 5 },
      ];

    const server_type = [
        { text: self.$t('view_settings_exchange_option_bybit'), value: "bybit" },// { text: "바이빗", value: "bybit" },
        //{ text: "바이낸스", value: "binance" },
        //{ text: "업비트", value: "upbit" },
      ];

    const performance_option = [
        { text: self.$t('view_settings_performance_option_animation_on'), value: "" },// { text: "기본 : 애니메이션 사용", value: "" },
        { text: self.$t('view_settings_performance_option_animation_off'), value: "1" },//{ text: "성능 : 애니메이션 끄기", value: "1" },
      ];
    const watch_option = [
        { text: self.$t('view_settings_watch_option_ticker'), value: "" },//{ text: "권장 : 티커 변동시 조건실행", value: "" },
        { text: self.$t('view_settings_watch_option_enhanced'), value: "1" },//{ text: "강력 : 시스템 허용 최대감시 조건실행 (고사양)", value: "1" },
      ];


      const bot_clear_option = [
        { text: self.$t('view_settings_bot_clear_off'), value: "" },//{ text: "최근 상태 보존", value: "auto" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:1}), value: 1 },//{ text: "모든 봇 끄기", value: "off" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:2}), value: 2 },//{ text: "모든 봇 끄기", value: "off" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:3}), value: 3 },//{ text: "모든 봇 끄기", value: "off" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:6}), value: 6 },//{ text: "모든 봇 끄기", value: "off" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:12}), value: 12 },//{ text: "모든 봇 켜기", value: "on" },
        { text: self.$t('view_settings_bot_clear_auto_msg',{hours:24}), value: 24 },//{ text: "모든 봇 켜기", value: "on" },
      ];

    const position_option = [
        { text: self.$t('view_settings_position_option_off'), value: "" },//{ text: "권장 : 티커 변동시 조건실행", value: "" },
        { text: self.$t('view_settings_position_option_bep'), value: "bep" },//{ text: "강력 : 시스템 허용 최대감시 조건실행 (고사양)", value: "1" },
      ];

    //현재 컴포넌트에서 사용할 데이터셋
    return {

      maxNumberOfChoices: 3,

      dialogSetRemoteCode:false,
      dialogInfoRemoteCode:false,
      otp_temp:'',


      temp_systemOption : null,
      is_changed: false,

      
      lang_option: lang_option,

      candle_type: candle_type,
      server_type: server_type,
      /*
      starting_option: [
        { text: "최근 상태 보존", value: "auto" },
        { text: "모든 봇 끄기", value: "off" },
        { text: "모든 봇 켜기", value: "on" },
      ],
      */
      bot_clear_option: bot_clear_option,

      starting_option: starting_option,
      performance_option: performance_option,
      watch_option: watch_option,
      position_option: position_option,
      /*
      market_type: [
        { text: "BTC/USDT", value: "BTC/USDT" },
        { text: "ETH/USDT", value: "ETH/USDT" },
        { text: "XRP/USDT", value: "XRP/USDT" },
        { text: "LTC/USDT", value: "LTC/USDT" },
        { text: "LUNA/USDT", value: "LUNA/USDT" },
        { text: "AVAX/USDT", value: "AVAX/USDT" },
        { text: "AXS/USDT", value: "AXS/USDT" },
        { text: "ATOM/USDT", value: "ATOM/USDT" },
        { text: "SAND/USDT", value: "SAND/USDT" },
        { text: "SOL/USDT", value: "SOL/USDT" },
      ],
      */

      otp_set_loaded : false,
      target_symbols:[],
      tmp_bot_clear_option: '',
      tmp_position_option: '',
    };
  },

  watch: {



    "systemOption.lang"(lang) {
        
        //this.$localData('lang',lang);
        this.$i18n.locale= lang;
        if(lang == 'ko-KR'){
          lang = 'ko';
        }

        let systemOption = this.$localData('systemOption');
        systemOption.lang = lang;


this.$localData('lang',lang);



         this.$localData('systemOption',systemOption);
        this.$store.dispatch("UPDATE_SETTINGS", systemOption);
        
         this.$buttonReload();

    },
    "systemOption.exchange"() {
      //업비트는 테스트모드가 없으므로 업비트 선택시 테스트 모드 해제
      //console.log(this.systemOption.exchange);
      if (this.systemOption.exchange == 'upbit') {
          this.systemOption.testmode = false;
      }
    },
dialogInfoRemoteCode (value){
  console.log('dialogInfoRemoteCode',value);
  if(value){
    setTimeout(() => {
        window.createQRcode({
						text: 'https://remote.crypto25.net/bots/'+this.userInfo.user_hash,

						width: 120,
						height: 120,
						quietZone: 0,
						colorDark: "#000000",
						colorLight: "#ffffff",

//						PI: '#1976d2',
	//					dotScale: 2,

						correctLevel: window.QRCode.CorrectLevel.M // L, M, Q, H
					});
    }, 300);

  }
},
    dialogSetRemoteCode(){
      if(this.$nvl(this.dialogSetRemoteCode,false) && this.otp_temp!=""){

        setTimeout(() => {
          
          this.otp_temp= '';
          this.$refs.otp_input.focus();
        })

      }
      


    }

  },

  computed: {
    systemOption() {
      return this.$store.state.systemOption;
    },
    userInfo() {
      return this.$store.state.userinfo;
    },
    market_type(){

        
        let marketList = Object.values(this.$store.state.marketlist);
        marketList = this.$_.filter( marketList , function(item) { return item.quote == 'USDT' });



      return marketList;
    },



    validationRules () {
      return this.maxNumberOfChoices ? [this.validateMaxNumberOfChoices] : []
    }




  },
  methods: {


    removeSymbol (item) {
        const target_symbol = item.symbol;
        let target_symbols = Object.values(this.target_symbols);
        let removed_symbols = this.$_.filter( target_symbols , function(item) { return item != target_symbol });

        //console.log(target_symbol);
        //console.log(removed_symbols);
        this.target_symbols = removed_symbols;

      },




  validateMaxNumberOfChoices (selectedChoices)  {

              //selectedChoices =   selectedChoices.splice(0, this.maxNumberOfChoices);



      return selectedChoices.length <= this.maxNumberOfChoices || `Choose ${this.maxNumberOfChoices} at most.` 
    },



    maxCoinCheck(){
      this.systemOption.symbols =   this.systemOption.symbols.splice(0, this.maxNumberOfChoices);

    },


    async getMarketList(){
      try {


         // const pairs = await state.exchange.loadMarkets();
          //commit('getMarketsPair', pairs)

          //console.warn('getMarketsPair',pairs);

          const marketList = await this.$store.state.exchange.loadMarkets();
          this.market_type = Object.values(marketList);
          this.market_type = this.$_.filter( this.market_type , function(item) { return item.quote == 'USDT' });




          console.log('marketList',this.market_type);


      } catch (error) {
          console.log(error)
      }
      },





    async setRemoteCode(otp){
              const self =this;
      
      if(otp.length != 6){
        //alert('otp를 정확히 입력해주세요.'); //$t('view_settings_bt_reset_settings')
        alert(self.$t('view_settings_code_otp_error')); //$t('view_settings_bt_reset_settings')
        return
      }


      //서버에 otp를 저장한다.
      //서버에서는 pw로 취급하여 otp가 등록됐는지 안됐는지만 리턴받아준다.

                 this.$axios.post(`${self.$store.state.systemOption.server_url}/api/setotp.php`,JSON.stringify({
                   user_hash: self.userInfo.user_hash,
                   api_key: self.systemOption.api.apiKey,
                   otp: otp,
                 }) ,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      'Cache-Control': 'no-cache',
                    },
                  })
                  .then(async res => {
                    console.log('updateBot',res.data)
                    if(res.data.success){
                        //alert('원격코드 등록완료');
                        self.otp_temp= '';

                        
                            let alertDialogInfo = {
                              timeout: 2000,
                              persistent:true,
                              //emoji: "😆",
                              title: self.$t('view_settings_code_otp_updating'),//"원격코드 등록중 ...",
                              firstLineText:  self.$t('view_settings_code_otp_updating_txt'),// " 등록을 위해 재구동합니다.",
                              //secondLineText: "다시 확인해주세요.",
                              // thirdLineText: "셋째줄",

                              };
                            self.$store.dispatch("openAlertDialog", alertDialogInfo);
              
                            setTimeout(() => {
                                self.$buttonRelaunch();
                            }, 2000);



                    }
                    self.dialogSetRemoteCode = false;
                    self.dialogInfoRemoteCode = false;
                    self.otp_set_loaded = true;
                    return res
                  })
                  .catch(err => {
                      console.log(err);
                    alert(self.$t('view_settings_code_otp_updating_fail'), +err);
                    self.dialogSetRemoteCode = false;
                    self.dialogInfoRemoteCode = false;

                    return false
                  })





    },
    clearRemoteCode(){
      console.log();
      //서버의 otp를 취소한다.
      //로컬에서 자신의 api/secret가 확인됐다는 가정하에 있기때문에 자유롭게 가능하다.

              const self =this;
                 this.$axios.post(`${self.$store.state.systemOption.server_url}/api/setotp.php`,JSON.stringify({
                   user_hash: self.userInfo.user_hash,
                   api_key: self.systemOption.api.apiKey,
                   otp: '',
                 }) ,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      'Cache-Control': 'no-cache',
                    },
                  })
                  .then(async res => {
                    console.log('updateBot',res.data)
                    if(res.data.success){
                        self.otp_temp= '';

                    
                            let alertDialogInfo = {
                              timeout: 2000,
                              persistent:true,
                              //emoji: "😆",
                              title: self.$t('view_settings_code_otp_updating_unset'), //"원격코드 해제중...",
                              firstLineText: self.$t('view_settings_code_otp_updating_unset_txt'), //"해제를 위해 재구동합니다.",
                              //secondLineText: "다시 확인해주세요.",
                              // thirdLineText: "셋째줄",

                              };
                            self.$store.dispatch("openAlertDialog", alertDialogInfo);
              
                            setTimeout(() => {
                                self.$buttonRelaunch();
                            }, 2000);




                        

                    }else{
                       alert(self.$t('view_settings_code_otp_updating_unset_fail'));
                    }
                    self.dialogSetRemoteCode = false;
                    self.dialogInfoRemoteCode = false;
                    self.otp_set_loaded = true;
                    return res
                  })
                  .catch(err => {
                      console.log(err);
                    alert(self.$t('view_settings_code_otp_updating_unset_fail'));
                    self.dialogSetRemoteCode = false;
                    self.dialogInfoRemoteCode = false;

                    return false
                  })
    },

    joinBybit: function () {

        console.log('joinBybit');
        //shell.openExternal(this.$store.state.serverinfo.url_app_banner_bybit);   

    },

    apiCreate: function () {

        console.log('apiCreate');
        //shell.openExternal(this.$store.state.serverinfo.url_app_link_api_create);
      
    },

    isChanged(){

        //시스템 설정 불러오기
        let json_temp_systemOption = this.temp_systemOption;

        //시스템 설정 불러오기
        let json_systemOption = JSON.stringify(this.systemOption);

        if(json_temp_systemOption == json_systemOption){
          console.log('저장된 값과 같음');
          this.is_changed = false;
        }else{
          console.log('저장된 설정값과 다름');
          this.is_changed = true;
        }


      //maxCoinchanged
      this.target_symbols =   this.target_symbols.splice(0, this.maxNumberOfChoices);



    },

    async saveSettings() {
      const self= this;

      let systemOption = this.systemOption;
      systemOption.symbols = this.target_symbols;
      if(systemOption.symbols.length < 1){
        //alert('1개 이상의 코인을 선택해주세요.');
        alert(self.$t('view_settings_code_not_enough_coin_length'));
        return;
      }
      
      systemOption.bot_clear_option = this.tmp_bot_clear_option;
      systemOption.position_option = this.tmp_position_option;

      this.$saveSettings(systemOption);






    },
    restoreSettings() {


        //시스템 설정 불러오기
        let temp_systemOption = JSON.parse(this.temp_systemOption);

        console.log('시스템설정 복원완료',this.systemOption);
        this.$store.dispatch("UPDATE_SETTINGS", temp_systemOption);



      //console.log('시스템설정 systemOptionForm',this.systemOptionForm.exchange);
      //console.log('시스템설정 systemOption',this.systemOption.exchange);
      //this.systemOptionForm = this.systemOption;
      


    },

    importSettings() {
      const self = this;

//this.$importSettings();

              let alertDialogInfo = {
                timeout: 0,
                //emoji: "😆",
                width: 800,
                title: self.$t('view_settings_code_import_settings_dialog_title'), // `스냅샷 불러오기`,
                firstLineText: self.$t('view_settings_code_import_settings_dialog_txt'), //"가져올 백업파일을 선택해주세요.",
                //secondLineText: "<small></small>",
                /*secondLineText: 
                `<textarea id="form_restore_settings" style="width:100%;;background:#eee;;height:100px;padding:10px;border-radius:4px;font-size:9px;color:#888;line-height:1;" placeholder="이곳에 가져올 설정을 붙여넣기 하거나 크립토25 데이터 파일을 선택해주세요."
                onchange="document.querySelector('#fileinput').value='';" onkeydown="document.querySelector('#fileinput').value=''""></textarea>
                <input type="file" id="fileinput" style="margin-top:10px; " 
                onchange="document.querySelector('#form_restore_settings').value=''"/>`,*/
                secondLineText: 
                `<input type="file" id="fileinput" style="margin-top:10px;margin-bottom:10px;" 
                onchange="document.querySelector('#form_restore_settings').value=''"/>`,
                thirdLineText: `<small>${self.$t('view_settings_code_import_settings_dialog_txt2')}</small>`,

                button1Text: self.$t('view_settings_code_import_settings_dialog_close'), //commit function name
                button1: "close", //commit function name
                button1Payload: null, //commit function name
                button1Color: "",
                button1Class: "",
/*
                button2Text: "가져오기", //commit function name
                //button2: "fileLoad", //commit function name
                button2: "fileLoad", //commit function name
                button2Payload: null, //commit function name

                button2Color: "primary",
                button2Class: "",
                */

              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);
 

              //txt 입력시의 실행은 $fileLoad에서 실행
              // 파일을 선택했을때의 실행
                setTimeout(() => {
                  const fileinput = document.querySelector('#fileinput');
                  console.log(fileinput);
                      fileinput.onchange = function(e) {
                          var f = e.target.files[0];
                          if (f) {
                              var reader = new FileReader();
                              reader.onload = function(e) {
                                  var text = e.target.result;


                                  let isJsonString = self.$isJsonString(text);
                                  if(isJsonString){

localStorage.clear();

                                
                                        let alertDialogInfo = {
                                          timeout: 2000,
                                          persistent:true,
                                          //emoji: "😆",
                                          title: self.$t('view_settings_code_file_loading_dialog_title'),
                                          firstLineText: self.$t('view_settings_code_file_loading_dialog_txt'),
                                          //secondLineText: "다시 확인해주세요.",
                                          // thirdLineText: "셋째줄",

                                          };
                                        self.$store.dispatch("openAlertDialog", alertDialogInfo);
                          





                                        var backup = JSON.parse(text);
                                        for (var key in backup){
                                          var value = decodeURIComponent(unescape(backup[key]));
                                          
                                          //불러오기할때, 끄기상태로 가져오기
                                          if(key =='systemOption'){
                                            let _systemOption = JSON.parse(value);
                                            _systemOption.starting_option = 'off';
                                            value = JSON.stringify(_systemOption);
                                          }

                                          window.localStorage.setItem(key, value);
                                        }
                                        //저장

                                        self.$buttonReload(); 
                                        //self.saveSettings();  
                                  }else{
                                      alert(self.$t('view_settings_code_file_loading_dialog_error'));

                                        self.$buttonReload(); 
                                  }

                                  //alert('Imported ' + Object.keys(backup).length + ' items from backup.')

                              };
                              reader.readAsText(f);
                          } 
                      };

                }, 300);
              // 파일을 선택했을때의 실행






    },



async exportSettings(){
  const self =this;

 const settings_json = await this.$exportSettings();

/*
        const _botOption = this.$localData('botOption');
        const _systemOption = this.$localData('systemOption');
        const _botList = this.$localData('botList');
        const _botLogs = this.$localData('botLogs');
        const exportOptions = {
          'systemOption' : _systemOption,
          'botOption' : _botOption,
          'botList' : _botList,
          'botLogs' : _botLogs,
        };
        console.log('export',exportOptions);


        const settings_json = JSON.stringify(exportOptions);

        //console.log('exportSettings',settings_json);
        //console.log('exportSettings',settings_obj.userInfo);
*/

              let alertDialogInfo = {
                timeout: 0,
                //emoji: "😆",
                width: 800,
                title: self.$t('view_settings_code_export_settings_dialog_title'), //`스냅샷 내보내기`,
                firstLineText: self.$t('view_settings_code_export_settings_dialog_txt'), //"스냅샷 파일을 저장해주세요.",
                //secondLineText: "<small></small>",
                //secondLineText: `<textarea onclick="this.select();this.setSelectionRange(0, 99999);navigator.clipoard.writeText(this.value);document.getElementById('clipboard_status').style.display='block';" style="width:100%;background:#eee;;height:100px;padding:10px;border-radius:4px;font-size:9px;color:#888;line-height:1;cursor:pointer;">${settings_json}</textarea><small id='clipboard_status' style='display:none;color:black;'>클립보드에 복사되었습니다.</small>`,
                thirdLineText:self.$t('view_settings_code_export_settings_dialog_txt2'), // "<small>현재의 봇설정 및 현황, 시스템에 설정된  <span style='color:red'>Api/Secret키가 포함되어있습니다. 타인과 절대로 공유하지 마세요.</span></small>",

                button1Text: self.$t('view_settings_code_export_settings_dialog_bt_download'), //"다운로드", //commit function name
                button1: "fileSave", //commit function name
                button1Payload: settings_json, //commit function name
                button1Color: "primary",
                button1Class: "",

                button2Text: self.$t('view_settings_code_export_settings_dialog_bt_close'), //"확인", //commit function name
                button2: "close", //commit function name
                button2Payload: null, //commit function name

                button2Color: "",
                button2Class: "",

              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);






},

    resetSettings() {
      const self = this;





              let alertDialogInfo = {
                timeout: 0,
                //emoji: "😆",
                title: self.$t('view_settings_code_reset_settings_dialog_title'), //"시스템 초기화",
                firstLineText: self.$t('view_settings_code_reset_settings_dialog_txt'), //"프로그램 설정을 기본값으로 되돌리겠습니까?",
                secondLineText: self.$t('view_settings_code_reset_settings_dialog_txt2'), //"봇설정도 함께 초기화 됩니다.",
                // thirdLineText: "셋째줄",

                button1Text: self.$t('view_settings_code_reset_settings_dialog_bt_reset'), //"네, 초기화하겠습니다", //commit function name
                button1: "resetSettings", //commit function name
                button1Payload: null, //commit function name
                button1Color: "grey",
                button1Class: "",
                button2Text: self.$t('view_settings_code_reset_settings_dialog_bt_close'), //"닫기", //commit function name
                button2: "close", //commit function name
                button2Payload: null, //commit function name
                button2Color: "",
                button2Class: "",
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);





    },

  },

  mounted() {
    this.temp_systemOption = JSON.stringify(this.$store.state.systemOption);



    this.maxNumberOfChoices = this.$nvl(this.$store.state.serverinfo.max_symbol,3);

    //this.getMarketList();


      this.target_symbols = this.systemOption.symbols;



      this.tmp_bot_clear_option = this.systemOption.bot_clear_option;


      this.tmp_position_option = this.$nvl(this.systemOption.position_option,'');

  },
};
</script>

<style></style>
